import React, { useState, useEffect, useCallback } from 'react';
import { SingleValue } from 'react-select';
import {
  ChartMapping,
  CurrentSelection,
  DateFilter,
  Filters,
  initWidget,
  TextBlockInterface,
  Widget,
  WidgetAlias,
} from 'models/WidgetModel';
import { useAxios } from 'hooks/useAxios';
import { RulesName } from 'constants/rulesName';
import notShowHierarchyFilter from 'constants/notShowHierarchyFilter';
import { getChartTypesModel, getChartTypeData } from 'services/Torogoz/TogorozApi';
import { DiyMockDataSource } from 'constants/diyMockDataSources';
import { library } from 'components';
import { ChartSelector, DIYChartOption, IconSelectors } from 'constants/chart-selector';
import { sortByString, SortOptions, arrayMove } from 'helpers/sorting';
import TextInput from 'core-ui/TextInput/TextInput';
import SelectChartInput from 'core-ui/SelectChartInput/SelectChartInput';
import MultipleSelectSortable, {
  SelectOptions,
} from 'core-ui/MultipleSelectSortable/MultipleSelectSortable';
import GroupSelectSortable, { Calculation } from 'core-ui/GroupSelectSortable/GroupSelectSortable';
import ColorInput from 'core-ui/ColorInput/ColorInput';
import './DiyDrawer.scss';
import { diyActionsOptions, diyChartOptions } from 'constants/diyDrawerOptions';
import OptionCheckbox from 'core-ui/optionCheckbox/optionCheckbox';
import CustomOptionCheckbox from 'core-ui/customOptionCheckbox/customOptionCheckbox';
import { optionsPropertyWidget } from 'constants/customOptionConstants';
import {
  strip,
  convertJsonToHierarchyFilter,
  convertJsonToAdvancedFilter,
} from 'helpers/utilities';
import SelectTrendInput, { TrendOptions, Trend } from 'core-ui/SelectTrendInput/SelectTrendInput';
import Accordion from 'core-ui/accordionDrawer/accordionDrawer';
import {
  colorFamilyInitialState,
  colorInitialState,
  ColorInputValue,
  ThemeInputValue,
} from 'constants/colorsInitialStates';
import ColorFamilyInput from 'core-ui/ColorFamilyInput/ColorFamilyInput';
import { useStore } from 'store/useGlobalStore';
import { initialGlobalState } from 'store/initialGlobalState';
import { helperTextByCalculation, helperTextByQuestion } from 'helpers/rulesHelperTexts';
import TextBlockControllers from 'components/TextBlockControllers/TextBlockControllers';
import SelectDecimalPlaces from 'core-ui/SelectDecimalPlaces/SelectDecimalPlaces';
import { ChartOptionSelector } from 'constants/chartOptionsSelector';
import ModalComponent from '../DiyCalendarContainer/DiyCalendarContainer';
import DateRangeInput from '../../core-ui/filteringDatePicker/filteringDatePicker';
import HierarchyFilters from 'core-ui/cx2HierarchyFilter/cx2HierarchyFilter';
import QueryBuilder, { IQueryData } from 'components/QueryBuilder/QueryBuilder';
import DataGridMetrics from 'components/DataGridMetrics/DataGridMetrics';
import AdvancedFiltersComponent from 'core-ui/cx2AdvancedFilters/cx2AdvancedFilters';

type MinMax = {
  Min: number;
  Max: number;
};
interface Rules {
  Name: string;
  Questions: MinMax;
  Calculations: MinMax;
}

type RuleType = 'Questions' | 'Calculations';

type DiyDrawerProps = {
  removeWidget: (id: number) => void;
};

const mockDataSource = DiyMockDataSource;
const DiyDrawer: React.FC<DiyDrawerProps> = ({ removeWidget }) => {
  const { response, axiosFetch, loading } = useAxios();
  const { response: responseData, axiosFetch: axiosFetchData, loading: loadingData } = useAxios();
  const {
    customWidget,
    widgets,
    widgetsList,
    endpoints,
    isConsumer,
    setState,
    selectedDiyChildChart,
    dataSourceType,
    dashboardView,
    useDiyHierarchyFilters,
    useAdvancedFilters,
  } = useStore((state) => ({
    customWidget: state.customWidget,
    widgets: state.widgets,
    widgetsList: state.widgetsList,
    endpoints: state.endpoints,
    isConsumer: state.isConsumer,
    setState: state.setState,
    selectedDiyChildChart: state.selectedDiyChildChart,
    dataSourceType: state.dataSourceType,
    dashboardView: state.dashboardView,
    useDiyHierarchyFilters: state.configDashboard.useDiyHierarchyFilters,
    useAdvancedFilters: state.configDashboard.useAdvancedFilters,
  }));

  const [payLoad, setPayLoad] = useState<string>('');
  const [showDetail, setShowDetail] = useState<boolean>(true);
  const [chartTypes, setChartTypes] = useState<DIYChartOption[]>();
  const [chartQuestions, setChartQuestions] = useState<SelectOptions[]>();
  const [selectedQuestions, setSelectedQuestions] = useState<SelectOptions[]>([]);
  const [chartFilters, setChartFilters] = useState<any[]>([]);
  const [selectedHierarchyFilters, setSelectedHierarchyFilters] = useState<any[]>([]);
  const [selectedAdvancedFilters, setSelectedAdvancedFilters] = useState<any[]>([]);
  const [hasHierarchyFilters, setHasHierarchyFilters] = useState<boolean>(false);
  const [hasAdvancedFilters, setHasAdvancedFilters] = useState<boolean>(false);
  const [chartCalculations, setChartCalculations] = useState<SelectOptions[]>([]);
  const [selectedCalculations, setSelectedCalculations] = useState<Calculation[]>([]);
  const [selectedFilteringDateRange, setSelectedFilteringDateRange] = useState<any>({});
  const [filteredQuestions, setFilteredQuestions] = useState<SelectOptions[]>([]);
  const [filteredCalculations, setFilteredCalculations] = useState<SelectOptions[]>([]);
  const [goalValues, setGoalValues] = useState<any>();
  const [yAxisValues, setYAxisValues] = useState<any>();
  const [hierarchyFiltersValues, setHierarchyFiltersValues] = useState<any>([]);
  const [advancedFiltersValues, setAdvancedFiltersValues] = useState<any>([]);
  const [chartTrend, setChartTrend] = useState<TrendOptions[]>([]);
  const [selectedChartTrend, setSelectedChartTrend] = useState<TrendOptions>();
  const [applicableRules, setApplicableRules] = useState<Rules[]>([]);
  const [responseRules, setResponseRules] = useState<Rules[]>([]);
  const [ruleName, setRuleName] = useState<string>('');

  const [widgetTitle, setWidgetTitle] = useState<string>('');
  const [requiredDisplayName, setRequiredDisplayName] = useState<boolean>(false);
  const [requiredQuestionsCalculations, setRequiredQuestionsCalculations] =
    useState<boolean>(false);
  const [requiredQuestions, setRequiredQuestions] = useState<boolean>(false);
  const [requiredCalculations, setRequiredCalculations] = useState<boolean>(false);
  const [textContentIsValid, setTextContentIsValid] = useState<boolean>(true);
  const [touchedQC, setTouchedQC] = useState<boolean>(false);
  const [currentAlias, setCurrentAlias] = useState<DIYChartOption>();
  const [selectedColor, setSelectedColor] = useState<ColorInputValue>(colorInitialState);
  const [selectedColorFamily, setSelectedColorFamily] =
    useState<ThemeInputValue>(colorFamilyInitialState);
  const [tempColor, setTempColor] = useState<string[]>([]);
  const [tempFamilyColor, setTempFamilyColor] = useState<string[]>([]);
  const [textBlockData, setTextBlockData] = useState<any>();
  const [decimalPlaces, setDecimalPlaces] = useState<any>(-1);

  const [queryData, setQueryData] = useState<IQueryData[]>([]);
  const [availableCollections, setAvailableCollections] = useState<SelectOptions[]>([]);

  const [queryIsValid, setQueryIsValid] = useState<boolean>(false);

  const excludedChartTypes = [library.CORPORATE_PULSE, library.GEOMAP];
  const chartsWithColor = [
    library.VERTICAL_BAR_CHART,
    library.HORIZONTAL_BAR_CHART,
    library.BULLET_CHART,
  ];
  const chartsWithColorFamilies = [
    library.PIE_CHART,
    library.HORIZONTAL_SINGLE_STACKED_BAR_CHART,
    library.INDIVIDUAL_METRIC,
  ];
  const chartsWithTrend = [library.VERTICAL_BAR_CHART, library.STACKED_BAR_CHART];

  const DataGridWithTrend = ['OneQuestionOneCalculation'];
  const actionsByChart = diyActionsOptions;
  const optionsByChart = diyChartOptions;
  const chartTypeLabel = {
    VerticalBarChart: 'Vertical Bar',
    PieChart: 'Doughnut',
    HorizontalSingleStackedBarChart: 'Horizontal Single Stacked',
    DynamicTable: 'Data Grid',
    DataGridMetrics: 'Data Grid Metrics',
    DataGrid: 'Data Grid',
    MetricSummary: 'Summary Metric Stats',
    HorizontalBarChart: 'Horizontal Bar',
    StackedBarChart: 'Grouped Bar/Line',
    GeoMap: 'Geo Map',
    IndividualMetric: 'Individual Metric',
    TextBlock: 'Text Block',
    BulletChart: 'Bullet Chart',
  };
  const chartsWithTextControllers = [library.TEXT_BLOCK];

  const setters: { [key: string]: React.Dispatch<React.SetStateAction<any>> } = {
    GoalLine: setGoalValues,
    YAxisValueComponent: setYAxisValues,
  };

  const getSetter = (key: string) => {
    return setters[key];
  };

  const getSetterValues = (key: string, options: any) => {
    switch (key) {
      case 'GoalLine':
        return options.goal;
      case 'YAxisValueComponent':
        return options.optYAxisValues;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (
      queryData.length === 0 ||
      queryData.some((x) => x.query === '' || x.dataCollectionGuid === '')
    ) {
      setQueryIsValid(false);
      return;
    }

    const customWidgetConfig = customWidget.customWidgetConfig;

    customWidgetConfig && (customWidgetConfig.QueryCollections = queryData);
    setQueryIsValid(true);
    setState({ customWidget: { ...customWidget, customWidgetConfig: customWidgetConfig } });
  }, [queryData]);

  useEffect(() => {
    const oldPayLoad = customWidget.payload;
    if (oldPayLoad != undefined) {
      setPayLoad(customWidget.payload.DataSetGuid);
    }
  }, []);

  useEffect(() => {
    const newOptions: any = { ...customWidget.options };
    if (goalValues) {
      const optionsWithGoal: any = { ...newOptions, goal: goalValues };
      setState({ customWidget: { ...customWidget, options: optionsWithGoal } });
    }
  }, [goalValues]);

  useEffect(() => {
    const newOptions: any = { ...customWidget.options };
    if (yAxisValues) {
      const optionsWithYAxisValues: any = { ...newOptions, optYAxisValues: yAxisValues };
      setState({ customWidget: { ...customWidget, options: optionsWithYAxisValues } });
    }
  }, [yAxisValues]);

  useEffect(() => {
    const getData = async () => {
      const url = customWidget.hasQuery
        ? `${endpoints.getChartTypesModel}?hasQuery=true`
        : endpoints.getChartTypesModel;
      await getChartTypesModel(url, axiosFetch, isConsumer);
    };
    getData();
  }, []);

  useEffect(() => {
    if (response) {
      if (Array.isArray(response)) {
        initializeChartTypes(response);
      } else {
        initializeChartQuestions(response);
        initializeQueryCollections(responseData);
        setResponseRules(response?.Rules || []);
        filterRules(response?.Rules || []);
      }
    }
  }, [response]);

  useEffect(() => {
    if (responseData) {
      initializeChartQuestions(responseData);
      initializeChartFilters(responseData);
      initializeQueryCollections(responseData);
      setResponseRules(responseData?.Rules || []);
      filterRules(responseData?.Rules || []);
    }
  }, [responseData]);

  useEffect(() => {
    if (widgetTitle) {
      const timeOutId = setTimeout(() => setCustomTitle(widgetTitle), 500);
      setState({ customWidget: { ...customWidget, displayName: widgetTitle } });
      return () => clearTimeout(timeOutId);
    }
  }, [widgetTitle]);

  useEffect(() => {
    if (textBlockData) {
      const newOptions: any = { ...customWidget.options };
      newOptions.textBlock = textBlockData;
      setState({ customWidget: { ...customWidget, options: newOptions } });
    }
  }, [textBlockData]);

  useEffect(() => {
    if (selectedColor) {
      const colors = [...customWidget.colors];
      if (colors[selectedColor.index]) {
        colors[selectedColor.index] = selectedColor.color;
      } else {
        colors?.push(selectedColor.color);
      }
      setTempColor(colors);
    }
  }, [selectedColor]);

  useEffect(() => {
    if (selectedColorFamily) {
      setTempFamilyColor(selectedColorFamily.theme);
    }
  }, [selectedColorFamily]);

  useEffect(() => {
    if (chartsWithColor.includes(customWidget.alias))
      setState({ customWidget: { ...customWidget, colors: tempColor } });
  }, [tempColor]);

  useEffect(() => {
    if (chartsWithColorFamilies.includes(customWidget.alias))
      setState({ customWidget: { ...customWidget, colors: tempFamilyColor } });
  }, [tempFamilyColor]);

  const filterRules = (rules: Rules[]) => {
    const filteredRules = rules.filter((rule: any) => currentAlias?.rules?.includes(rule.RuleName));
    setApplicableRules(filteredRules);
  };
  const setCustomTitle = (title: string) => {
    if (chartTypes) {
      setState({ customWidget: { ...customWidget, displayName: title } });
    }
  };

  const initializeChartTypes = (types: any) => {
    const chartOptions: DIYChartOption[] = [];
    if (!types.some((t: any) => t.chartType == 'TextBlock'))
      types.push({
        chartType: 'TextBlock',
        label: 'Text Block',
        description: '',
        rules: [],
      });

    const getFirstRule = (chartType: string, rules: string[] = []) => {
      const [firstRule] = rules;
      if (firstRule) return firstRule;
      return chartType;
    };

    function buildOptions(
      types: DIYChartOption[],
      options: DIYChartOption[] = [],
      isChild = false,
      parent = 'root'
    ) {
      for (const type of types) {
        // eslint-disable-next-line prettier/prettier
        const icon = type.chartType as ChartSelector;
        const wd = getWidgetByChartType(type);
        if (wd) {
          if (!excludedChartTypes.includes(type.chartType)) {
            options.push({
              ...type,
              label: type?.label
                ? type.label
                : chartTypeLabel[type.chartType as keyof typeof chartTypeLabel],
              icon: IconSelectors.icons[icon],
              component: wd.alias,
              value: isChild ? getFirstRule(type.chartType, type.rules) : type.chartType,
              isChild,
              isOpen: false,
              parent,
            });
          }
          if (type.children && type.children?.length > 0) {
            buildOptions(type.children as DIYChartOption[], chartOptions, true, type.chartType);
          }
        }
      }
    }

    function SortChartOptions(options: DIYChartOption[]) {
      const indexes = options.map((element, index) => (element.parent === 'DataGrid' ? index : -1));
      if (indexes.length > 0) {
        indexes.forEach((j) => {
          if (j != -1) options[j].label = 'DataGrid ' + options[j].label;
        });
      }
      chartOptions.sort((a, b) => {
        const textA = a.label.toUpperCase();
        const textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      if (indexes.length > 0) {
        indexes.forEach((j) => {
          if (j != -1) options[j].label = options[j].label.replace('DataGrid ', '');
        });
      }
    }

    buildOptions(types, chartOptions);
    SortChartOptions(chartOptions);
    initAliasValue(chartOptions);
    setChartTypes(chartOptions);
  };

  const getWidgetByChartType = (type: DIYChartOption) => {
    let wd: any;
    for (const widget of widgetsList) {
      if (widget.alias.length) {
        const wFound = widget.alias.find(
          (alias: WidgetAlias) => alias.controllerName === type.chartType
        );
        if (
          (wFound || widget.component === type.chartType) &&
          widget.dataSourceTypes.includes(dataSourceType[0])
        ) {
          wd = {
            alias: widget.component,
            controllerName: wFound?.controllerName || widget.component,
            rules: type.rules,
          };
          break;
        }
      }
    }
    return wd;
  };

  const initAliasValue = (options: DIYChartOption[]) => {
    if (
      customWidget.alias === library.CUSTOM_WIDGET ||
      customWidget.alias === library.QUERY_WIDGET ||
      customWidget.hasQuery
    ) {
      setCurrentAlias(options[0]);
    } else if (customWidget.alias === 'TextBlock') {
      // If widget is a TextBlock return option based on customWidget.alias
      const option = options.find((o) => o.chartType === customWidget.alias);
      setCurrentAlias(option);
    } else {
      const [rule] = customWidget?.customWidgetConfig?.Rules || [];
      const option =
        customWidget.customWidgetConfig?.Metadata?.isChild && rule
          ? options.find(
              (o) =>
                o.chartType === customWidget.customWidgetConfig?.Chart &&
                o.rules?.includes(rule.RuleName) &&
                o.isChild
            )
          : options.find((o) => o.chartType === customWidget.customWidgetConfig?.Chart);
      setState({ selectedDiyChildChart: option });
      setCurrentAlias(option);
    }
  };

  const initializeChartQuestions = (ChartData: any) => {
    const questions: Array<any> = ChartData?.Questions?.length ? ChartData?.Questions : [];
    const options: SelectOptions[] = questions.map((question) => ({
      value: question?.QuestionLabel,
      label: strip(question?.ShortText),
      longText: strip(question?.LongText),
      raw: question,
    }));
    sortByString(options, 'label', SortOptions.ASC);
    getCalculations(ChartData);

    if (chartsWithTrend.includes(ChartData.Chart) || ChartData.Chart === 'DataGrid')
      getTrend(ChartData);
    else setChartTrend([]);
    setChartQuestions(options);

    // Get selected options from custom widget config
    if (customWidget?.customWidgetConfig?.Questions?.length) {
      const selectedOptions = customWidget?.customWidgetConfig?.Questions?.map((question: any) => ({
        value: question?.QuestionLabel,
        label: strip(question?.ShortText),
        longText: strip(question?.LongText),
        raw: question,
      }));
      setSelectedQuestions(selectedOptions);
    }
  };

  const initializeQueryCollections = (ChartData: any) => {
    const collections: Array<any> = ChartData?.DataCollectionsList?.length
      ? ChartData?.DataCollectionsList
      : [];
    const options: SelectOptions[] = collections.map((collection) => ({
      value: collection?.DataCollectionGuid,
      label: strip(collection?.DataCollectionName),
      longText: '',
      raw: '',
    }));
    sortByString(options, 'label', SortOptions.ASC);

    setAvailableCollections(options);
  };

  // Initialize chart filters
  const initializeChartFilters = (ChartData: any) => {
    if (ChartData?.Filters) {
      setChartFilters(ChartData?.Filters.DateFilter);
      setCurrentFilter(ChartData);
    } else {
      setChartFilters([]);
    }
  };

  // set filter object for widgets
  const setCurrentFilter = (chartData: any) => {
    let defaultFilter: DateFilter;
    let dateFilter: DateFilter | undefined | null;
    const editedWidget: Widget = customWidget;
    // if does not have current selection will add default which is MTD
    if (chartData.Filters && !chartData.Filters.CurrentSelection) {
      defaultFilter = chartData.Filters.DateFilter.find(
        (o: any) => o.DisplayName === 'Month To Date'
      );
      if (
        editedWidget.customWidgetConfig &&
        !(
          editedWidget.customWidgetConfig.Filters &&
          editedWidget.customWidgetConfig.Filters.CurrentSelection
        )
      ) {
        dateFilter = defaultFilter;
        const currentSelectionvalue: CurrentSelection = {
          DateFilter: dateFilter,
        };
        const filterValue: Filters = { CurrentSelection: currentSelectionvalue };
        editedWidget.customWidgetConfig.Filters = filterValue;
      }
      setSelectedFilteringDateRange(
        dateFilter == undefined
          ? editedWidget.customWidgetConfig?.Filters?.CurrentSelection?.DateFilter
          : defaultFilter
      );
    }
  };

  const getCalculations = (ChartData: any) => {
    if (ChartData?.Calculations) {
      const questions = ChartData?.Questions;
      const calculations = ChartData?.Calculations;
      const calculationGroups = calculations.reduce(
        (acc: { [x: string]: never[] }, calculation: { [x: string]: any }) => {
          const key = calculation['QuestionName'];
          const curGroup = acc[key] ?? [];
          return {
            ...acc,
            [key]: [
              ...curGroup,
              { ...calculation, value: calculation.Id, label: strip(calculation.DisplayName) },
            ],
          };
        },
        {}
      );

      const calculationgroups = questions.reduce((acc: Array<any>, question: any) => {
        const key = question['QuestionLabel'];
        if (calculationGroups[key])
          acc.push({
            ['label']: question['ShortText'],
            ['longText']: question['LongText'],
            ['options']: calculationGroups[key],
          });
        return acc;
      }, []);
      setChartCalculations(calculationgroups);
      if (customWidget?.customWidgetConfig?.Calculations?.length) {
        setSelectedCalculations(
          customWidget.customWidgetConfig.Calculations.map((calculation: any) => ({
            ...calculation,
            value: calculation.Id,
            label: strip(calculation.DisplayName),
          }))
        );
      }
    } else {
      setChartCalculations([]);
    }
  };

  const getTrend = (data: any) => {
    const options: TrendOptions[] = [];
    if (data?.Trend) {
      const trend = data.Trend;

      for (const t of trend) {
        options.push({
          label: t.DisplayName,
          value: t.TimePeriodType,
          Trend: t,
        });
        if (options?.length === 1 && !customWidget?.customWidgetConfig?.Trend?.length) {
          setSelectedChartTrend(options[0]);
          const trend = options[0].Trend;
          trend.SelectedValue = options[0].Trend.IntervalMinValue;
        }
      }
    }
    setChartTrend(options);
  };

  const getDataSourceforPreview = (newAlias: string, trend?: any) => {
    if (!selectedDiyChildChart?.isChild)
      return mockDataSource.find((widget) => widget.alias === newAlias);

    const [ruleName] = selectedDiyChildChart.rules || [];
    let alias = ruleName ? ruleName : newAlias;
    // save ruleName when widget is child
    setRuleName(alias);
    if (alias === RulesName.OneQuestionOneCalculation) {
      let trendValue =
        trend !== undefined && trend.length > 0
          ? trend[0].DisplayName
          : selectedChartTrend?.Trend.DisplayName;
      if (selectedChartTrend?.Trend?.DisplayName !== undefined)
        trendValue = selectedChartTrend?.Trend?.DisplayName;
      alias = alias + trendValue;
      return mockDataSource.find((widget) => widget.alias === alias);
    }
    return (
      mockDataSource.find((widget) => widget.alias === alias) ||
      mockDataSource.find((widget) => widget.alias === newAlias)
    );
  };

  // returns alias to look in diyDrawerOptions
  const getOptionsByAlias = (newAlias: string) => {
    if (!selectedDiyChildChart?.isChild) return newAlias;

    // if it not child will return the ones for dynamictables
    const [ruleName] = selectedDiyChildChart.rules || [];
    const alias = ruleName ? ruleName : newAlias;
    return alias;
  };

  const checkAlias = (chartType: string, alias: string) => {
    if (alias === library.DYNAMIC_TABLE) {
      const [selectedRule] = customWidget.customWidgetConfig?.Rules || [];
      const [selectedCurrentRule] = currentAlias?.rules || [];
      const checkRule = selectedRule === undefined || selectedRule.RuleName !== selectedCurrentRule;
      return chartType !== 'DataGrid' || checkRule;
    }

    return chartType !== alias;
  };

  useEffect(() => {
    if (!currentAlias) return;
    let getDatCtrl = false;
    if (
      currentAlias?.chartType &&
      checkAlias(currentAlias.chartType, customWidget.alias) &&
      chartTypes?.length
    ) {
      const newAlias = currentAlias.component || customWidget.alias;
      const selectedWidget = widgetsList.find((widget) => widget.component === newAlias);
      const dataSource = getDataSourceforPreview(newAlias);
      const newDataSource: any = {
        ...dataSource,
        payload: {
          ...dataSource?.payload,
          ControllerName: currentAlias.chartType,
        },
      };

      delete newDataSource.alias;
      filterRules(responseRules || []);
      if (selectedWidget) {
        const [alias] = selectedWidget.alias.filter(
          (alias: WidgetAlias) => alias.controllerName === currentAlias.chartType
        );
        const newWidget: any = initWidget(selectedWidget, alias, customWidget.config);

        setTouchedQC(false);
        if (newWidget.description) delete newWidget.description;
        delete newWidget.viewId;
        delete newWidget.image;
        delete newWidget.displayName;
        delete newWidget.isCustom;
        newWidget.options = defaultOptions(newWidget, customWidget);
        newWidget.config.i = customWidget?.config?.i;
        newWidget.config.x = customWidget?.config?.x;
        newWidget.config.y = customWidget?.config?.y;
        newWidget.colors = colorByChartType(newAlias);
        const editedWidget: Widget = {
          ...customWidget,
          customWidgetConfig: {
            ...customWidget.customWidgetConfig,
            DataSource: setSourceType(currentAlias?.chartType),
            Metadata: {
              ...customWidget.customWidgetConfig?.Metadata,
              isChild: selectedDiyChildChart?.isChild ? true : false,
            },
          },
          ...newWidget,
          ...newDataSource,
          // eslint-disable-next-line prettier/prettier
        } as Widget;
        setState({ customWidget: editedWidget });
        getDatCtrl = true;
        const getData = async () => {
          const getDataUrl = getDataUrlEndpoint(currentAlias);
          await getChartTypeData(getDataUrl, axiosFetchData, isConsumer);
        };

        if (selectedQuestions && selectedQuestions?.length > 0) setSelectedQuestions([]);
        if (selectedCalculations && selectedCalculations?.length > 0) setSelectedCalculations([]);
        if (selectedChartTrend && selectedChartTrend?.Trend?.SelectedValue) {
          const newTrendOptions = {
            ...selectedChartTrend,
            Trend: {
              ...selectedChartTrend?.Trend,
              SelectedValue: selectedChartTrend.Trend.IntervalMinValue,
            },
          };
          setSelectedChartTrend(newTrendOptions);
        }
        if (setSourceType(currentAlias.chartType) !== 'generic') getData();
      }
    }

    if (customWidget.alias !== 'CustomWidget' && chartTypes?.length && !getDatCtrl) {
      filterRules(responseRules || []);
      const getData = async () => {
        const getDataUrl = getDataUrlEndpoint(currentAlias);
        await getChartTypeData(getDataUrl, axiosFetchData, isConsumer);
      };
      if (
        customWidget?.customWidgetConfig?.DataSource !== 'generic' &&
        setSourceType(customWidget.alias) !== 'generic'
      )
        getData();
    }
  }, [currentAlias]);

  const getDataUrlEndpoint = (currentAlias?: any) => {
    let getDataUrl = '';
    if (selectedDiyChildChart?.isChild && !customWidget.hasQuery) {
      getDataUrl = endpoints.getData
        .replace('Unknown', currentAlias?.chartType)
        .replace('UnknownBusinessRule', currentAlias.value);
    } else if ((selectedDiyChildChart?.isChild && customWidget.hasQuery) || customWidget.hasQuery) {
      getDataUrl = endpoints.getData
        .replace('Unknown', currentAlias?.chartType)
        .replace('UnknownBusinessRule', currentAlias.value)
        .replace('False', 'True');
    } else {
      getDataUrl = endpoints.getData.replace('Unknown', currentAlias?.chartType);
    }
    return getDataUrl;
  };

  const defaultOptions = (sw: any, cw: any) => {
    const opAlias = getOptionsByAlias(sw.alias);
    let newOptions: any = { ...cw.options };
    newOptions = cw.alias === sw.alias ? cw.options : sw.options;
    if (sw.alias === library.DYNAMIC_TABLE)
      return {
        ...newOptions,
        table: {
          ...newOptions.table,
          showTotals: opAlias === 'OneQuestionManyCalculations' ? true : false,
        },
      };
    return newOptions;
  };

  const setSourceType = (cAlias: string) => {
    if (chartsWithTextControllers.includes(cAlias)) {
      return 'generic';
    }
    return dashboardView === 'survey' ? 'survey' : 'chat';
  };

  const handleChartTypeChange = (newValue: SingleValue<DIYChartOption>) => {
    setState({ selectedDiyChildChart: newValue });

    if (newValue?.chartType) {
      setCurrentAlias(newValue);
    }
  };

  const handleQuestionChange = (newValue: Array<SelectOptions>) => {
    if (newValue) {
      setSelectedQuestions(newValue);
    }
  };

  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        setSelectedQuestions((selectedQuestions) => {
          const oldIndex = selectedQuestions.findIndex((i) => i.value === active.id);
          const newIndex = selectedQuestions.findIndex((i) => i.value === over.id);
          return arrayMove(selectedQuestions, oldIndex, newIndex);
        });
      }
    },
    [setSelectedQuestions]
  );

  const handleCalculationsDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        setSelectedCalculations((selectedCalculations) => {
          const oldIndex = selectedCalculations.findIndex((i) => i.value === active.id);
          const newIndex = selectedCalculations.findIndex((i) => i.value === over.id);
          return arrayMove(selectedCalculations, oldIndex, newIndex);
        });
      }
    },
    [setSelectedCalculations]
  );

  const handleCalculationChange = (newValue: Array<Calculation>) => {
    if (newValue) {
      setSelectedCalculations(newValue);
    }
  };

  const handleChartTrendChange = (newValue: TrendOptions) => {
    if (newValue) {
      const newTrend = {
        ...newValue,
        Trend: { ...newValue.Trend, SelectedValue: newValue.Trend?.IntervalMinValue },
      };
      setSelectedChartTrend(newTrend);
    }
  };

  const handleCountChange = (event: { target: { value: number } }) => {
    const val = Number(event.target.value);
    if (event && selectedChartTrend) {
      const newTrend = {
        ...selectedChartTrend,
        Trend: { ...selectedChartTrend?.Trend, SelectedValue: val },
      };
      setSelectedChartTrend(newTrend);
    }
  };

  const getAlias = () => {
    // Check if the a chart is selected from the dropdown chart type
    if (currentAlias?.chartType && currentAlias?.chartType !== '' && currentAlias?.alias)
      return currentAlias?.alias;
    // Checking if the widget is recently created and still not saved
    if (customWidget.controllerName) return customWidget.controllerName;
    // Checking if the widget is already saved and the controller name is available on payload.
    if (customWidget?.payload?.ControllerName) return customWidget?.payload?.ControllerName;

    if (customWidget.customWidgetConfig?.Chart) {
      if (typeof customWidget.customWidgetConfig?.Chart !== 'string') {
        return ChartMapping[customWidget.customWidgetConfig?.Chart];
      } else {
        return customWidget.customWidgetConfig.Chart;
      }
    }

    return 'Unknown';
  };

  useEffect(() => {
    if (!currentAlias) {
      ruleSelector([], []);
      return;
    }

    // Get Hierarchy filters value
    const hierarchyFilters = hierarchyFiltersValues
      ? {
          HierarchyFilters:
            hierarchyFiltersValues.length > 0 || hasHierarchyFilters
              ? hierarchyFiltersValues
              : customWidget.customWidgetConfig?.Filters?.CurrentSelection?.HierarchyFilters,
        }
      : {};

    // Get Advanced filters value
    const advancedFilters = advancedFiltersValues
      ? {
          AdvancedFilters:
            advancedFiltersValues.length > 0 || hasAdvancedFilters
              ? advancedFiltersValues
              : customWidget.customWidgetConfig?.Filters?.CurrentSelection?.AdvancedFilters,
        }
      : {};

    // Set hierarchy filters object based on one expected
    const filters = {
      CurrentSelection: {
        ...customWidget.customWidgetConfig?.Filters?.CurrentSelection,
        ...hierarchyFilters,
        ...advancedFilters,
      },
    };

    setSelectedHierarchyFilters([...hierarchyFiltersValues]);

    setSelectedAdvancedFilters([...advancedFiltersValues]);

    const questions = selectedQuestions
      ? selectedQuestions.map((question) => question.raw)
      : customWidget.customWidgetConfig?.Questions;

    const calculations = selectedCalculations
      ? selectedCalculations
      : customWidget.customWidgetConfig?.Calculations;

    // Validate if the selected chart type allows trend.
    const newAlias = currentAlias.component || customWidget.alias;
    const trend = customWidget?.customWidgetConfig?.Trend;
    const dataSource = getDataSourceforPreview(newAlias, trend);
    const newDataSource: any = {
      ...dataSource,
      payload: {
        ...dataSource?.payload,
        ControllerName: currentAlias.chartType,
      },
    };

    delete newDataSource.alias;

    const Trend: Trend[] =
      selectedChartTrend?.Trend &&
      (chartsWithTrend.includes(customWidget.alias) ||
        DataGridWithTrend.includes(getOptionsByAlias(currentAlias?.alias as string)))
        ? [selectedChartTrend.Trend]
        : [];
    if (
      !selectedChartTrend &&
      customWidget?.customWidgetConfig?.Trend &&
      customWidget?.customWidgetConfig?.Trend?.length === 1
    ) {
      const tredCustomPeriod: string =
        customWidget.customWidgetConfig.Trend[0].TimePeriodType || '';
      const trendOption = chartTrend.find((trend: any) => trend.value === tredCustomPeriod);
      setSelectedChartTrend({
        ...trendOption,
        Trend: {
          ...trendOption?.Trend,
          SelectedValue: customWidget.customWidgetConfig.Trend[0].SelectedValue,
        },
      } as TrendOptions);
    }
    const selectedRule = ruleSelector(questions, calculations);

    const chartAlias = getAlias();
    setState({
      customWidget: {
        ...customWidget,
        ...newDataSource,
        isMockData: true,
        customWidgetConfig: {
          ...customWidget.customWidgetConfig,
          Chart: chartAlias,
          Trend,
          DecimalFormat:
            typeof customWidget.options.decimalPlaces !== 'undefined'
              ? customWidget.options.decimalPlaces
              : decimalPlaces >= 0
              ? decimalPlaces
              : -1,
          Questions: questions || [],
          Calculations: calculations || [],
          Rules: selectedRule.length ? selectedRule : customWidget.customWidgetConfig?.Rules || [],
          Filters: filters,
        },
      },
    });
  }, [
    selectedQuestions,
    selectedCalculations,
    selectedChartTrend,
    decimalPlaces,
    hierarchyFiltersValues,
    advancedFiltersValues,
    selectedFilteringDateRange,
  ]);

  useEffect(() => {
    if (selectedQuestions) {
      const newCalculations = chartCalculations.filter((c) => {
        return selectedQuestions.every((q) => {
          return q.label !== c.label;
        });
      });
      setFilteredCalculations(newCalculations);
      return;
    }
    setFilteredCalculations(chartCalculations);
  }, [selectedQuestions, chartCalculations]);

  useEffect(() => {
    if (selectedCalculations) {
      const newQuestions = chartQuestions?.filter((q) => {
        return selectedCalculations.every((c) => {
          return c.QuestionName !== q.value;
        });
      });
      setFilteredQuestions(newQuestions || []);
      return;
    }
    setFilteredQuestions(chartQuestions || []);
  }, [selectedCalculations, chartQuestions]);

  const ruleSelector = (sQuestions?: SelectOptions[], sCalculations?: SelectOptions[]) => {
    if (sQuestions && sCalculations && applicableRules.length) {
      const sRule = applicableRules.filter(
        (rule: Rules) =>
          sQuestions.length >= rule.Questions.Min &&
          sQuestions.length <= rule.Questions.Max &&
          sCalculations.length >= rule.Calculations.Min &&
          sCalculations.length <= rule.Calculations.Max
      );

      const qFailedRule = applicableRules.filter(
        (rule: Rules) =>
          !(sQuestions.length >= rule.Questions.Min && sQuestions.length <= rule.Questions.Max) &&
          sCalculations.length >= rule.Calculations.Min &&
          sCalculations.length <= rule.Calculations.Max
      );

      const cFailedRule = applicableRules.filter(
        (rule: Rules) =>
          sQuestions.length >= rule.Questions.Min &&
          sQuestions.length <= rule.Questions.Max &&
          !(
            sCalculations.length >= rule.Calculations.Min &&
            sCalculations.length <= rule.Calculations.Max
          )
      );

      const isAnyRuleFailed = sRule.length === 0;
      const isQuestionRuleFailed = qFailedRule.length > 0;
      const isCalculationRuleFailed = cFailedRule.length > 0;
      setRequiredQuestionsCalculations(isAnyRuleFailed);
      if (isAnyRuleFailed && (isQuestionRuleFailed || isCalculationRuleFailed)) {
        setRequiredQuestions(isQuestionRuleFailed);
        setRequiredCalculations(isCalculationRuleFailed);
      } else if (isAnyRuleFailed && !(isQuestionRuleFailed || isCalculationRuleFailed)) {
        setRequiredQuestions(true);
        setRequiredCalculations(true);
      } else {
        setRequiredQuestions(false);
        setRequiredCalculations(false);
      }
      return sRule;
    }

    setRequiredQuestionsCalculations(true);
    setRequiredCalculations(true);
    setRequiredCalculations(true);
    return [];
  };

  const showQuestionCalculation = (ruleType: RuleType): boolean => {
    const rulesMax = applicableRules.filter((rule: any) => rule[ruleType].Max > 0);
    if (rulesMax.length) return true;
    return false;
  };

  const initColor = (index: number): string => {
    if (tempColor?.length > 0) return tempColor[index];
    if (customWidget.colors) return customWidget.colors[index];
    return colorInitialState.color;
  };

  const initColorFamily = (): string[] => {
    if (tempFamilyColor?.length > 0) return tempFamilyColor;
    if (customWidget.colors) return customWidget.colors;
    return colorFamilyInitialState.theme;
  };

  const colorByChartType = (currentAlias: string) => {
    if (chartsWithColor.includes(currentAlias)) {
      return tempColor;
    } else if (chartsWithColorFamilies.includes(currentAlias)) {
      return tempFamilyColor;
    }
    const widget = widgetsList.find((widget) => widget.component === currentAlias);
    const defaultChartColors = widget?.defaults.colors ? widget?.defaults.colors : [];
    return defaultChartColors;
  };

  const colorSelectorComponent = () => {
    if (chartsWithColor.includes(customWidget.alias))
      return (
        <div className="diy-drawer-section">
          <ColorInput
            inputTitle={'Color'}
            index={0}
            defaultColor={initColor(0)}
            setSelectedColor={setSelectedColor}
          />
        </div>
      );
    if (chartsWithColorFamilies.includes(customWidget.alias))
      return (
        <div className="diy-drawer-section">
          <ColorFamilyInput
            inputTitle={'Color Families'}
            defaultColorFamily={initColorFamily()}
            selectColorFamily={setSelectedColorFamily}
          />
        </div>
      );
    return null;
  };

  const initActions = (currentCustomWidget: any) => {
    const { alias, options } = currentCustomWidget;
    const newChartActions = structuredClone(actionsByChart);
    const selectedChartActions = newChartActions?.[alias as keyof typeof newChartActions];

    options.actions?.map((item: any) => {
      if (selectedChartActions) {
        const action = selectedChartActions.find((a: any) => a.actionName === item);
        if (action) {
          action.isChecked = true;
        }
      }
    });

    return (
      <>
        {selectedChartActions?.map((item: any) =>
          typeof item.isChecked === 'undefined' &&
          typeof item.value !== 'undefined' &&
          item.childComponent === undefined ? (
            <SelectDecimalPlaces
              {...{
                option:
                  customWidget.options.decimalPlaces && customWidget.options.decimalPlaces >= 0
                    ? {
                        actionName: 'decimalPlaces',
                        displayName: 'Decimal Places',
                        value: customWidget.options.decimalPlaces,
                      }
                    : item,
                onChangeHandler: handleDecimalChange,
              }}
              key={item.actionName}
            />
          ) : (
            <OptionCheckbox
              {...{ option: item, onChangeHandler: handleActions }}
              key={item.actionName}
            />
          )
        )}
      </>
    );
  };

  const handleActions = (event: { target: { name: string; checked: boolean } }) => {
    const newActions = [...(customWidget.options.actions || [])];
    if (newActions.includes(event.target.name)) {
      !event.target.checked && newActions.splice(newActions.indexOf(event.target.name), 1);
    } else {
      event.target.checked && newActions.push(event.target.name);
    }
    const newOptions = { ...customWidget.options, actions: newActions };
    setState({ customWidget: { ...customWidget, options: newOptions } });
  };

  const initOptions = (currentCustomWidget: any) => {
    const { alias, options } = currentCustomWidget;
    const opAlias = getOptionsByAlias(alias);
    const newChartOptions = structuredClone(optionsByChart);
    const selectedChartOptions = newChartOptions?.[opAlias as keyof typeof newChartOptions];
    if (alias === library.DYNAMIC_TABLE) {
      selectedChartOptions?.map((option: any) => {
        if (typeof option.isChecked !== 'undefined') {
          option.isChecked = options.table[option.actionName];
        } else {
          option.value =
            currentCustomWidget?.customWidgetConfig?.DecimalFormat !== -1
              ? currentCustomWidget?.customWidgetConfig?.DecimalFormat
              : 2;
        }
      });
    } else {
      selectedChartOptions?.map((option: any) => {
        if (typeof option.isChecked !== 'undefined') {
          option.isChecked = options[option.actionName];
        } else {
          option.value = options[option.actionName] ? option.value : options[option.actionName];
        }
      });
    }
    return (
      <>
        {selectedChartOptions?.map((item: any) =>
          typeof item.isChecked === 'undefined' &&
          typeof item.value !== 'undefined' &&
          item.childComponent === undefined ? (
            <SelectDecimalPlaces
              {...{
                option:
                  customWidget.options.decimalPlaces && customWidget.options.decimalPlaces >= 0
                    ? {
                        actionName: 'decimalPlaces',
                        displayName: 'Decimal Places',
                        value: customWidget.options.decimalPlaces,
                      }
                    : item,
                onChangeHandler: handleDecimalChange,
              }}
              key={item.actionName}
            />
          ) : item.childComponent !== undefined ? (
            <CustomOptionCheckbox
              {...{
                option: item,
                onChangeHandler: handleOptions,
                ChildComponentName: item.childComponent as ChartOptionSelector,
                onSetGoalValuesChange: getSetter(item.childComponent),
                customValues: getSetterValues(item.childComponent, options),
                showChildComponent: item.isChecked,
              }}
              key={item.actionName}
            />
          ) : (
            <OptionCheckbox
              {...{ option: item, onChangeHandler: handleOptions }}
              key={item.actionName}
            />
          )
        )}
      </>
    );
  };
  const handleDecimalChange = (
    event: { label: string; value: number; name: string },
    target: any
  ) => {
    const newOptions: any = { ...customWidget.options };
    newOptions[optionsPropertyWidget.decimalPlaces] = event.value;
    setState({ customWidget: { ...customWidget, options: newOptions } });
    // set state for decimal
    setDecimalPlaces(event.value);
  };

  const handleOptions = (event: { target: { name: string; checked: boolean } }) => {
    if (customWidget.alias === 'DynamicTable') {
      const newOptions: any = { ...customWidget.options.table };
      newOptions[event.target.name as keyof typeof newOptions] = event.target.checked;
      setState({
        customWidget: { ...customWidget, options: { ...customWidget.options, table: newOptions } },
      });
    } else {
      const newOptions: any = { ...customWidget.options };

      newOptions[event.target.name as keyof typeof newOptions] =
        event.target.name === 'decimalPlaces' ? 2 : event.target.checked;
      // Add custom default options for actions.
      if ('showLineChart' in newOptions) {
        newOptions.defaults = {
          ...newOptions.defaults,
          SWITCH_BAR_LINE: newOptions.showLineChart ? 'line' : 'bars',
        };
      }

      setState({ customWidget: { ...customWidget, options: newOptions } });
    }
  };

  const handleDoneClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const editedWidget: Widget = customWidget;
    if (payLoad != '' && payLoad != undefined) {
      customWidget.payload.DataSetGuid = payLoad;
    }
    if (customWidget?.customWidgetConfig?.Trend?.length && selectedChartTrend?.Trend) {
      const trendDone: Trend = editedWidget?.customWidgetConfig?.Trend
        ? editedWidget?.customWidgetConfig?.Trend[0]
        : {};
      if (editedWidget.customWidgetConfig) editedWidget.customWidgetConfig.Trend = [trendDone];
    }

    // For DynamicTable/OneQuestionOneCalculation Date Filter should be null to avoid take MTD as default
    // Set filter as null when widget is TextBlock
    if (editedWidget.customWidgetConfig?.Filters && customWidget.alias === 'TextBlock') {
      editedWidget.customWidgetConfig.Filters = null;
    } else if (
      editedWidget.customWidgetConfig?.Filters?.CurrentSelection &&
      customWidget.customWidgetConfig?.Rules?.length &&
      customWidget.customWidgetConfig?.Rules[0]?.RuleName === 'OneQuestionOneCalculation'
    ) {
      if (
        editedWidget.customWidgetConfig.Filters &&
        editedWidget.customWidgetConfig.Filters.CurrentSelection &&
        editedWidget.customWidgetConfig.Filters.CurrentSelection.DateFilter
      ) {
        editedWidget.customWidgetConfig.Filters.CurrentSelection!.DateFilter = null as
          | DateFilter
          | null
          | undefined;
      }
    }

    setState({
      selectedDiyChildChart: null,
      customWidget: editedWidget,
    });
    setState({ diySave: true });
    setState({ diyMode: false });
  };

  const handleCancelClick = () => {
    const selectedWidget = widgets.find((widget) => widget.config.i === customWidget.config.i);

    if (
      selectedWidget?.alias === library.CUSTOM_WIDGET ||
      selectedWidget?.alias === library.QUERY_WIDGET
    ) {
      removeWidget(parseInt(customWidget.config.i));
    }

    setState({ customWidget: initialGlobalState.customWidget, selectedDiyChildChart: null });
    setState({ diyMode: false });
  };

  const isDisabled = (customWidget: any) => {
    if (customWidget?.options?.optYAxisValues)
      return (
        customWidget?.options?.optYAxisValues?.min > customWidget?.options?.optYAxisValues?.max ||
        customWidget?.options?.optYAxisValues?.max < customWidget?.options?.optYAxisValues?.min
      );
    if (customWidget.hasQuery) return !queryIsValid;
    if (chartsWithTextControllers.includes(customWidget.alias)) return !textContentIsValid;
    if (requiredDisplayName || applicableRules.length) return requiredQuestionsCalculations;
    return false;
  };

  // modal for filtering dates
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  // set filtering date range
  const setFilteringDateRange = (newValue: any) => {
    if (newValue) {
      const editedWidget: Widget = customWidget;
      if (editedWidget.customWidgetConfig) {
        const dateFilter: DateFilter = newValue.value;
        if (
          editedWidget.customWidgetConfig.Filters?.CurrentSelection &&
          editedWidget.customWidgetConfig.Filters?.CurrentSelection?.DateFilter
        )
          editedWidget.customWidgetConfig.Filters.CurrentSelection!.DateFilter = dateFilter;
      }
      setSelectedFilteringDateRange(newValue.value);
    }
  };

  const onHierarchyFilterChange = (newHierarchyFilterValue: any) => {
    const hierarchyFilterValue = convertJsonToHierarchyFilter(
      newHierarchyFilterValue?.SelectedFilters
    );
    if (newHierarchyFilterValue?.SelectedFilters !== undefined) setHasHierarchyFilters(true);
    setHierarchyFiltersValues([...hierarchyFilterValue]);
  };

  // handle advanced filters object
  const onAdvancedFilterChange = (newAdvancedFilterValue: any) => {
    const advancedFilterValue = convertJsonToAdvancedFilter(
      newAdvancedFilterValue?.SelectedFilters
    );
    if (newAdvancedFilterValue?.SelectedFilters !== undefined) setHasAdvancedFilters(true);
    setAdvancedFiltersValues([...advancedFilterValue]);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <ModalComponent
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onSelectDateRange={setFilteringDateRange}
        chartFilters={chartFilters}
        currentSelection={
          customWidget.customWidgetConfig?.Filters
            ? customWidget.customWidgetConfig?.Filters.CurrentSelection
            : {}
        }
      />
      <div className={`diy-drawer ${showDetail ? 'open' : 'closed'}`}>
        <div className="diy-drawer-container">
          <div className="diy-drawer-title">Edit Widget</div>

          <div className={`diy-drawer-handle`} onClick={() => setShowDetail(!showDetail)}>
            <div
              className="diy-drawer-handle-icon"
              data-testid="showHideIcon"
              style={{ rotate: 'revert' }}
            >
              {showDetail ? 'Hide' : 'Show'}
            </div>
          </div>
          <div className="diy-drawer-contents">
            <div className="diy-drawer-section">
              <TextInput
                customWidgetTitle={customWidget?.displayName}
                setWidgetTitle={setWidgetTitle}
                required={requiredDisplayName}
                setRequired={setRequiredDisplayName}
                maxLength={50}
              />
              {chartTypes && currentAlias ? (
                <SelectChartInput
                  loading={loading}
                  options={chartTypes}
                  selectChartType={handleChartTypeChange}
                  defaultChartType={currentAlias}
                />
              ) : null}
              {!customWidget.hasQuery &&
              chartTrend &&
              chartTrend.length &&
              (DataGridWithTrend.includes(
                getOptionsByAlias(currentAlias?.alias as string) as string
              ) ||
                chartsWithTrend.includes(currentAlias?.chartType as string)) ? (
                <SelectTrendInput
                  loading={loading}
                  options={chartTrend}
                  selectTrend={handleChartTrendChange}
                  defaultTrend={selectedChartTrend}
                  selectCount={handleCountChange}
                  selectedChart={selectedChartTrend}
                  setSelectedChartTrend={setSelectedChartTrend}
                />
              ) : null}

              {!customWidget.hasQuery &&
              chartQuestions?.length &&
              showQuestionCalculation('Questions') ? (
                <MultipleSelectSortable
                  data-testid="question-selector"
                  loading={loading}
                  handleDragEnd={handleDragEnd}
                  options={filteredQuestions}
                  selectedChange={handleQuestionChange}
                  selectedValues={selectedQuestions}
                  label="Questions"
                  onBlurEvent={() => (!touchedQC ? setTouchedQC(true) : null)}
                  helperText={helperTextByQuestion(applicableRules)}
                />
              ) : null}
              {!customWidget.hasQuery &&
              touchedQC &&
              requiredQuestions &&
              requiredQuestionsCalculations ? (
                <span className="diy-required-field">
                  This combination is not allowed with the specified chart
                </span>
              ) : null}
              {!customWidget.hasQuery &&
              chartCalculations?.length &&
              showQuestionCalculation('Calculations') ? (
                <GroupSelectSortable
                  loading={loading}
                  handleDragEnd={handleCalculationsDragEnd}
                  options={filteredCalculations}
                  selectedChange={handleCalculationChange}
                  selectedValues={selectedCalculations}
                  label="Calculations"
                  onBlurEvent={() => (!touchedQC ? setTouchedQC(true) : null)}
                  helperText={helperTextByCalculation(applicableRules)}
                />
              ) : null}
              {!customWidget.hasQuery &&
              touchedQC &&
              requiredCalculations &&
              requiredQuestionsCalculations ? (
                <span className="diy-required-field">
                  This combination is not allowed with the specified chart
                </span>
              ) : null}
            </div>
            {customWidget.hasQuery ? (
              <QueryBuilder
                customWidget={customWidget}
                availableOptions={availableCollections}
                onChange={(querydata) => setQueryData(querydata)}
              />
            ) : null}
            {customWidget.alias && colorSelectorComponent()}
            {chartsWithTextControllers.includes(customWidget.alias) ? (
              <div className="diy-drawer-section">
                <TextBlockControllers
                  textBlockData={{ ...(customWidget?.options?.textBlock as TextBlockInterface) }}
                  setTextBlockData={setTextBlockData}
                  setTextContentIsValid={setTextContentIsValid}
                />
              </div>
            ) : null}
            <div className="diy-drawer-section">
              <Accordion title="Options">
                {customWidget.options && initActions(customWidget)}
                {customWidget.options && initOptions(customWidget)}
              </Accordion>
            </div>
            {!customWidget.hasQuery && !notShowHierarchyFilter.includes(customWidget.alias) ? (
              <div className="diy-drawer-section">
                <Accordion title="Filters">
                  {!chartsWithTrend.includes(customWidget.alias as string) &&
                  !DataGridWithTrend.includes(ruleName) ? (
                    <div className="">
                      <label className="diy-filters-label">Calendar</label>
                      <DateRangeInput
                        inputTitle=""
                        handleOpenMoldal={openModal}
                        dateSelection={selectedFilteringDateRange}
                        currentSelection={
                          customWidget.customWidgetConfig?.Filters
                            ? customWidget.customWidgetConfig?.Filters.CurrentSelection
                            : {}
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {useDiyHierarchyFilters ? (
                    <div className="diy-drawer-hierarchy-filters-section">
                      <label className="diy-filters-label">Filters</label>
                      <HierarchyFilters
                        {...{
                          onSetHierarchyFilters: onHierarchyFilterChange,
                          selectedHierarchyValues: hierarchyFiltersValues,
                          id: customWidget.id,
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {useAdvancedFilters ? (
                    <div className="diy-drawer-advanced-filters-section">
                      <label className="diy-filters-label">Advanced Filters</label>
                      <AdvancedFiltersComponent
                        {...{
                          onSetAdvancedFilters: onAdvancedFilterChange,
                          selectedAdvancedFiltersValues: advancedFiltersValues,
                          id: customWidget.id,
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </Accordion>
              </div>
            ) : null}
          </div>
          <div className="diy-drawer-controls">
            <button className="diy-button diy-button-enabled-inverse" onClick={handleCancelClick}>
              Cancel
            </button>
            <button
              className="diy-button diy-button-enabled"
              onClick={handleDoneClick}
              disabled={isDisabled(customWidget)}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiyDrawer;
